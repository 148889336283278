import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Logo from 'components/icons/Logo';
import NavLink from 'components/menu/NavLink';
import { useApiPageProtect } from 'hooks/usePageProtect';
import { type ReactElement } from 'react';
import { type LayoutProps } from '../../pages/_app';

interface LoginLayoutProps extends LayoutProps {
    children: ReactElement
}
const LoginLayout = ({ children }: LoginLayoutProps) => {
    const { protect } = useApiPageProtect({ needUnauth: true });

    return protect(
        <>
            <Box
                sx={{
                    backgroundColor: 'primary.400',
                    width: '100%',
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'stretch'
                }}>
                <Stack spacing={4} alignItems={'center'} justifyContent={'center'} py={3} width={'100%'} textAlign={'center'}>
                    <NavLink
                        href={'/'}
                        passHref={true}>
                        <Button
                            variant={'text'}
                            sx={{ width: '100%' }}
                        >
                            <Box
                                width={320}
                            >
                                <Logo extended={true} />
                            </Box>
                        </Button>
                    </NavLink>
                    {children}
                    <img
                        src="/LogoRegion.png"
                        alt="Region Centre Val de Loire"
                        height={70}
                        loading="lazy"
                    />
                </Stack>
            </Box>
        </>
    );
};

export default LoginLayout;
export type {
    LoginLayoutProps
};

