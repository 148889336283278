import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import FormCard from 'components/form/FVFormCard';
import FormPasswordField from 'components/form/FVFormPasswordField';
import rules from 'components/form/validationSchemaRules';
import LoginLayout from 'components/layout/LoginLayout';
import useApi from 'hooks/useApi';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import NextLink from 'next/link';
import { useCallback } from 'react';
import { useBoolean, useIsMounted } from 'usehooks-ts';

const Login = () => {
    const { login, askResetPassword } = useApi();
    const { createSnack, next } = useSnackbar();
    const { t } = useTranslation();

    //Toggle between login card and reset password card
    const loginFormSelected = useBoolean(true);
    const loginSubmitLoading = useBoolean(false);
    const askResetSubmitLoading = useBoolean(false);
    const isMounted = useIsMounted();

    const handleSubmitLogin = useCallback(
        (data: any) => {
            loginSubmitLoading.setTrue();
            return login({ ...data, redirectUri: '/', hideLoadingScreen: true })
                .catch(({ ...error }) => {
                    createSnack({
                        message: error?.error || 'unknown',
                        translateKey: 'pages.login.login_card.error',
                        severity: 'error'
                    });
                    if (error.error == 'invalid_grant') {
                        return Promise.reject({
                            errors: {
                                username: ['validation.invalid_grant'],
                                password: ['validation.invalid_grant']
                            },
                            message: 'layout.error.network.422',
                            severity: 'error'
                        });
                    }
                })
                .finally(() => {
                    if (isMounted()) loginSubmitLoading.setFalse();
                });
        },
        [loginSubmitLoading, login, isMounted, createSnack]
    );

    const handleSubmitAskReset = useCallback(
        (data: any) => {
            askResetSubmitLoading.setTrue();
            return askResetPassword({ ...data })
                .then(() => {
                    loginFormSelected.setTrue();
                    createSnack({
                        message: 'success',
                        translateKey: 'pages.login.ask_reset_card'
                    });
                })
                .catch(({ ...error }) => {
                    createSnack({
                        message: error?.error || 'not_sent',
                        translateKey: 'pages.login.ask_reset_card.error',
                        severity: 'error'
                    });
                })
                .finally(() => {
                    if (isMounted()) askResetSubmitLoading.setFalse();
                });
        },
        [
            askResetPassword,
            askResetSubmitLoading,
            createSnack,
            isMounted,
            loginFormSelected
        ]
    );

    return (
        <>
            <Container maxWidth={'sm'}>
                <Stack
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'stretch'}
                >
                    <Collapse in={loginFormSelected.value} mountOnEnter={true} unmountOnExit={true}>
                        <Box>
                            <FormCard
                                CardProps={{
                                    FormProps: {
                                        noValidate: false
                                    }
                                }}
                                header={{
                                    title: t('pages.login.login_card.title'),
                                    subheader: t('pages.login.login_card.subtitle')
                                }}
                                fields={[
                                    [
                                        {
                                            label: t('models.user.data.email'),
                                            name: 'username',
                                            type: 'email'
                                        },
                                        {
                                            label: t('models.user.data.password.password'),
                                            name: 'password',
                                            component: FormPasswordField
                                        }
                                    ]
                                ]}
                                data={{ username: '', password: '' }}
                                onSubmit={handleSubmitLogin}
                                actions={{
                                    switch: {
                                        text: t('pages.login.login_card.reset'),
                                        variant: 'text',
                                        order: 1,
                                        onClick: loginFormSelected.toggle
                                    },
                                    submit: {
                                        text: t('pages.login.login_card.submit'),
                                        variant: 'contained',
                                        order: 2,
                                        type: 'submit',
                                        loading: loginSubmitLoading.value
                                    }
                                }}
                                getValidationRule={() => rules.stringRequired}
                                createSnack={createSnack}
                            />
                        </Box>
                    </Collapse>
                    <Collapse in={!loginFormSelected.value} mountOnEnter={true} unmountOnExit={true}>
                        <Box>
                            <FormCard
                                CardProps={{
                                    FormProps: {
                                        noValidate: false
                                    }
                                }}
                                header={{ title: t('pages.login.ask_reset_card.title') }}
                                fields={[
                                    [
                                        {
                                            label: t('models.user.data.email'),
                                            name: 'email',
                                            type: 'email'
                                        }
                                    ]
                                ]}
                                data={{ email: '' }}
                                submitLoading={askResetSubmitLoading.value}
                                onSubmit={handleSubmitAskReset}
                                actions={{
                                    switch: {
                                        text: t('pages.login.ask_reset_card.back'),
                                        variant: 'text',
                                        order: 1,
                                        onClick: loginFormSelected.toggle
                                    },
                                    submit: {
                                        text: t('pages.login.ask_reset_card.submit'),
                                        variant: 'contained',
                                        order: 2,
                                        type: 'submit',
                                        loading: askResetSubmitLoading.value
                                    }
                                }}
                                getValidationRule={({ name }) => rules[name]}
                                createSnack={createSnack}
                            />
                        </Box>
                    </Collapse>
                </Stack>
                <Stack
                    direction="row"
                    alignItems={'center'}
                    justifyContent={'center'}
                    my={2}
                    spacing={1}
                    divider={
                        <Divider
                            orientation={'vertical'}
                            variant={'middle'}
                            sx={{ display: 'inline-block', borderBottom: '2px solid #fff', width: { xs: 4, sm: 6 } }}
                        />
                    }
                >
                    <Link href={'https://www.welogin.fr/french-vadrouilles'} target={'_blank'} underline={'hover'} variant={'body2'} sx={{ color: '#fff' }}>
                        {t('layout.menu.side.discover')}
                    </Link>
                    <NextLink href={'/legals'} passHref={true} legacyBehavior={true}>
                        <Link underline={'hover'} variant={'body2'} sx={{ color: '#fff' }}>
                            {t('layout.menu.side.legal')}
                        </Link>
                    </NextLink>
                    <NextLink href={'/contact'} passHref={true} legacyBehavior={true}>
                        <Link underline={'hover'} variant={'body2'} sx={{ ml: 2, color: '#fff' }}>
                            {t('pages.contact.layout.menu')}
                        </Link>
                    </NextLink>
                    <NextLink href={'/legals/cga'} passHref={true} legacyBehavior={true}>
                        <Link underline={'hover'} variant={'body2'} sx={{ ml: 2, color: '#fff' }}>
                            {t('pages.cgu.layout.menu')}
                        </Link>
                    </NextLink>

                </Stack>
            </Container>
        </>
    );
};

Login.Layout = LoginLayout;

export default Login;
